/*===> Begin square icon <===*/
.square-icon {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 152px;
	min-height: 137px;
	padding: 15px;
	background: #fff;
	box-shadow: 0px 10px 22px rgba(42, 158, 121, 0.14);
	border-radius: 13px;
	transition: all .3s;

	&:hover {
		box-shadow: none;
	}

	img {
		margin-bottom: 15px;
	}

	p {
		margin-bottom: 0;
		font-family: $main_font;
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 23px;
		color: $head_color;
	}

	@include breakpoint(medium) {
		width: 107px;
		min-height: 96px;

		img {
			height: 40px;
			margin-bottom: 5px;
		}

		p {
			font-size: 14px;
		}
	}

	@include breakpoint(mobile) {
		width: 86px;
		min-height: 80px;
		padding-top: 5px;
		padding-bottom: 5px;

		img {
			height: 30px;
		}
	}
}
/*===> End square icon <===*/