/*===> Begin teacher-card <===*/
.teacher-card {
	position: relative;
	display: flex;
	max-width: 440px;
	min-height: 400px;
	margin-left: auto;
	margin-right: auto;
	padding: 45px 30px;
	background: #fff;
	box-shadow: 0px 10px 22px rgba(42, 135, 158, 0.14);
	border-radius: 11px;
	overflow: hidden;

	&.second-style {
		display: flex;
		flex-direction: column;
		padding: 0;
	
		.teacher-card-top {
			position: relative;
			padding-top: 30px;

			.avatar-circle {
				position: relative;
				width: 170px;
				height: 170px;
				margin-left: auto;
				margin-right: auto;
				border-radius: 50%;
				overflow: hidden;

				img {
					position: absolute;
					top: 0;
					left: 50%;
					transform: translateX(-50%);
				}
			}
	
			.cloud {
				position: absolute;
				top: 0;
				left: 50%;
				width: 607px;
				max-width: none;
				transform: translate(-50%, -50%);
			}
		}
	
		.teacher-card__avatar {
			position: static;
		}

		.teacher-card__info {
			width: 100%;
			max-width: none;
			padding: 20px 30px 50px;

			h5 {
				text-align: center;
			}

			p {
				text-align: center;
			}

			.soc-link {
				justify-content: center;
			}
		}
	} 

	.teacher-card__info {
		position: relative;
		z-index: 10;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		max-width: 160px;

		h5 {
			margin-bottom: 10px;
		}

		p {
			margin-bottom: 25px;
		}
	}

	.teacher-card__avatar {
		position: absolute;
		z-index: 2;
		top: 30px;
		right: -30%;
	}

	@include breakpoint(extra-small) {
		.teacher-card__info {
			p {
				font-size: 14px;
			}
		}
	}

	@include breakpoint(mobile) {
		padding-left: 20px;
		.teacher-card__avatar {
			right: -50%;
		}
	}
}
/*===> End teacher-card <===*/