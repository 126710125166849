/*===> Begin wave block <===*/
.wave-block {
	position: relative;
	overflow: hidden;
	padding: 150px 0;
	background-size: cover;
	// background: url(../img/blue_wave.svg);
	background-size: 100% 100%;

	&.reverce-wave {
		&::before {
			left: auto !important;
			right: 20%;
			transform: translateY(-45%) rotate(-9deg);
		}

		&::after {
			right: auto !important;
			left: 18%;
		}
	}

	&.wave-gallery {
		min-height: 1060px;
		display: flex;
		flex-direction: column;
		justify-content: center;

		@include breakpoint(medium) {
			min-height: 0;
		}
	}

	&.bg-cover {
		background-size: cover !important;
	}

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 18%;
		width: 407px;
		height: 170px;
		background: url(../img/cloudx2.png);
		background-size: cover;
		filter: brightness(0) invert(1);
		transform: translateY(-55%) rotate(0deg);
	}

	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		right: 20%;
		width: 362px;
		height: 150px;
		background: url(../img/cloudx2.png);
		background-size: cover;
		filter: brightness(0) invert(1);
		transform: translateY(60%);
	}

	@include breakpoint(leptop) {
		&.reverce-wave {
			&::before {
				right: 12%;
			}

			&::after {
				left: 12%;
			}
		}

		&::before {
			top: 0;
			left: 12%;
			width: 340px;
			height: 145px;
		}

		&::after {
			bottom: 0;
			right: 20%;
			width: 340px;
			height: 145px;
		}
	} 

	@include breakpoint(medium) {
		background-size: auto 100%;

		&.reverce-wave {
			&::before {
				right: 12%;
			}

			&::after {
				left: 12%;
			}
		}

		&::before {
			top: 0;
			left: 12%;
			width: 340px;
			height: 145px;
			transform: translateY(-59%) rotate(9deg);
		}

		&::after {
			bottom: 0;
			right: 20%;
			width: 300px;
			height: 125px;
			transform: translateY(50%);
		}
	}

	@include breakpoint(small) {
		padding: 120px 0;

		&.reverce-wave {
			&::before {
				right: 12%;
				transform: translateY(-59%) rotate(0deg);
			}

			&::after {
				left: 12%;
				transform: translateY(45%);
			}
		}

		&::before {
			top: 0;
			left: 12%;
			width: 200px;
			height: 85px;
			transform: translateY(-59%) rotate(7deg);
		}

		&::after {
			bottom: 0;
			right: 10%;
			width: 200px;
			height: 85px;
			transform: translateY(35%);
		}
	}

	@include breakpoint(mobile) {
		padding: 80px 0;
		background-position: 15% 0;

		&::before {
			top: 0;
			left: 12%;
			width: 200px;
			height: 85px;
			transform: translateY(-50%) rotate(5deg);
		}

		&::after {
			bottom: 0;
			right: 10%;
			width: 200px;
			height: 85px;
			transform: translateY(30%);
		}
	}
}
/*===> End wave block <===*/