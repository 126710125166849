/*===> Begin pricing-packages-card <===*/
.pricing-packages-card {
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 450px;
	margin-left: auto;
	margin-right: auto;
	padding: 40px 30px;
	background: #fff;
	box-shadow: 0px 14px 22px rgba(42, 135, 158, 0.14);
	border-radius: 12px;
	overflow: hidden;
	text-align: center;

	.pricing-packages-description {
		width: 100%;

		.pricing-packages-include {
			p {
				margin-bottom: 10px;
				padding-bottom: 10px;
				border-bottom: 1px solid rgba(#E9E9E9, .4);
				// border: 2px solid #E9E9E9;

				&:last-child {
					border-bottom: 0;
				}
			}
		}
	}

	.pricing-packages-img {
		margin-bottom: 15px;
	}

	.pricing-packages-price {
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: $head_font;
		font-size: 14px;
		text-align: center;

		.currency {
			font-size: 16px;
		}

		.price {
			margin: 0 10px;
			font-style: normal;
			font-weight: normal;
			font-size: 42px;
			line-height: 1em;
			text-align: center;
			color: $main_color;
		}
	}

	@include breakpoint(medium) {
		.pricing-packages-img {
			height: 120px;

			img {
				height: 100%;
			}
		}
	}
}
/*===> End pricing-packages-card <===*/