/*===> Begin top line <===*/
.top-line {
	display: flex;
	align-items: center;
	justify-content: space-between;


	/* &::after {
		content: '';
		position: fixed;
		z-index: -1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: #000;
		opacity: .3;
	} */


	@include breakpoint(medium) {
		flex-wrap: wrap;

		.logo {
			order: 0;
		}

		.main-nav {
			order: 2;
		}

		.header-controls {
			order: 1;
		}
	}

}
/*===> End top line <===*/	