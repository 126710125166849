/*COLORS*/
$main_color: #46AADC;
$main_color_light: #F0F8FB;
$hover_color: #46AADD;

$component_color_1: #FFAF60; // (orange)
$component_color_1_light: #FFD1AA; // (orange)
$component_color_2: #76BEBA; // (green)
$component_color_2_light: #B5D9D7; // (green)
$component_color_3: #7192BD; // (blue)
$component_color_3_light: #9FCFE9; // (blue)

$error_color: #c0392b;

$table_color: #EAF5F8;
$light_color: #F8FBFB;
$border_color: #CEDDE0;
$border_dark: #555555;
$bloquote_bg: #F0F8FA;

$border_testimonial_1: #EAF5F8;
$border_testimonial_2: #ECF5EE;
$border_testimonial_3: #F1EFEC;

$head_color: #3B4757;
$text_color: #777777;
$text_dark: #333333;


/*------------------------------------------------------------------
# [Color codes] 
#main_color				 : #46AADC;
#main_color_light		 : #F0F8FB;
#hover_color			 : #46AADD; 
#component_color_1		 : #FFAF60; // (orange)
#component_color_1_light : #FFD1AA; // (orange)
#component_color_2		 : #76BEBA; // (green)
#component_color_2_light : #B5D9D7; // (green)
#component_color_3		 : #7192BD; // (blue)
#component_color_3_light : #9FCFE9; // (blue) 
#table_color			 : #EAF5F8;
#light_color			 : #F8FBFB;
#border_color			 : #CEDDE0;
#border_dark			 : #555555;
#bloquote_bg			 : #F0F8FA;
 
#border_testimonial_1	 : #EAF5F8;
#border_testimonial_2	 : #ECF5EE;
#border_testimonial_3	 : #F1EFEC;
 
#head_color				 : #3B4757;
#text_color				 : #777777;
#text_dark				 : #333333;

# */

/*FONTS*/
$main_font: 'Roboto', sans-serif;
$head_font: 'Poppins', sans-serif;
$second_font: 'Radley', sans-serif;
$font_awsome: "Font Awesome 5 Free";

/*------------------------------------------------------------------
# [Fonts]

main_font   : 'Roboto'
head_font   : 'Poppins'
second_font : 'Radley'

# */

